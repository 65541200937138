import { NavigateFunction } from 'react-router-dom';

//navigation
export const handleNavigateToQA = (navigate: NavigateFunction) => {
  navigate('/clanky/qa');
};

export const handleNavigateToPrubeh = (navigate: NavigateFunction) => {
  navigate('/clanky/prubeh');
};

export const handleNavigateToAbout = (navigate: NavigateFunction) => {
  navigate('/o-mne');
};

export const handleNavigateToReservation = (navigate: NavigateFunction) => {
  navigate('/rezervace');
};

export const handleNavigateToThetaArticle = (navigate: NavigateFunction) => {
  navigate('/clanky/theta');
};

export const handleNavigateToWhyThetaArticle = (navigate: NavigateFunction) => {
  navigate('/clanky/proc-theta');
};

export const handleNavigateToFeedback = (navigate: NavigateFunction) => {
  navigate('/zpetna-vazba');
};

export const handleNavigateContact = (navigate: NavigateFunction) => {
  navigate('/kontakt');
};

export const handleNavigateToStvoritel = (navigate: NavigateFunction) => {
  navigate('/clanky/stvoritel');
};

export const handleNavigateToOpora = (navigate: NavigateFunction) => {
  navigate('/clanky/opora');
};
export const handleNavigateToOsamelost = (navigate: NavigateFunction) => {
  navigate('/clanky/osamelost');
};
export const handleNavigateToEmpatie = (navigate: NavigateFunction) => {
  navigate('/clanky/empatie');
};
export const handleNavigateToPozitivita = (navigate: NavigateFunction) => {
  navigate('/clanky/pozitivita');
};
export const handleNavigateToOceneni = (navigate: NavigateFunction) => {
  navigate('/clanky/oceneni');
};
export const handleNavigateToPrizpusobeni = (navigate: NavigateFunction) => {
  navigate('/clanky/prizpusobeni');
};

//rest
export const handleOpenFeedbackForm = () => {
  const url = 'https://forms.gle/Z9CCAmkb96KqyXES8';
  window.open(url, '_blank', 'noopener,noreferrer');
};

// src/components/SEO.tsx
import React from 'react';
import { Helmet } from 'react-helmet-async';

interface SEOProps {
  page: keyof typeof SEOData;
}

export const SEOData = {
  home: {
    title: 'Cesta Světla – Theta Healing',
    description:
      'Prožij naplno pocit, že si zasloužíš lásku a soucit. Objev cestu ke změně s metodou Theta Healing, která tě povede k úlevě, radosti, a osobnímu růstu. Cesta Světla je tu, aby ti pomohla nalézt sebe a žít plný, spokojený život.',
    keywords:
      'Theta Healing, osobní růst, sebeláska, respekt, empatie, soucit, laskavost, radost, změna, spirituální rozvoj, sebepoznání',
    url: 'https://www.cestasvetla.eu/',
    image: null,
  },
  about: {
    title: 'O mně – Můj příběh | Theta Healing a Osobní Růst',
    description:
      'Přečti si příběh Veroniky Hývlové, která překonala bolesti a objevila vnitřní svobodu. Díky metodě Theta Healing teď pomáhá lidem na jejich cestě k uzdravení, radosti a klidu v duši.',
    keywords:
      'O mně, Veronika Hývlová, Theta Healing, osobní příběh, sebeláska, psychické zdraví, uzdravení, spirituální rozvoj, změna života',
    url: 'https://www.cestasvetla.eu/about',
    image: null,
  },
  articles: {
    title: 'Články – Inspirace a příběhy | Otázky a odpovědi',
    description:
      'Objev inspirativní články, příběhy klientů a praktické tipy z oblasti Theta Healing a osobního růstu. Prozkoumej cesty k harmonii v těle, mysli a duši.',
    keywords:
      'články, inspirace, Theta Healing, osobní růst, příběhy klientů, praktické tipy, spirituální rozvoj, Veronika Hývlová, seberozvoj',
    url: 'https://www.cestasvetla.eu/articles',
    image: null,
  },
  feedback: {
    title: 'Zpětná vazba – Co říkají klienti',
    description:
      'Přečti si zpětnou vazbu od klientů a zjisti, jak jim Theta Healing pomohl na jejich cestě k osobnímu růstu. Sdílej i ty svůj názor a zkušenosti.',
    keywords:
      'zpětná vazba, klientské recenze, Theta Healing zkušenosti, osobní růst, recenze, Veronika Hývlová, spirituální cesta',
    url: 'https://www.cestasvetla.eu/feedback',
    image: null,
  },
  reservation: {
    title: 'Rezervace termínu – Theta Healing sezení',
    description:
      'Rezervuj si svůj termín na Theta Healing sezení. Možnost uplatnění slevových kódů. Těším se na společnou cestu ke změně a růstu!',
    keywords:
      'rezervace, Theta Healing, slevové kódy, osobní růst, spirituální terapie, Veronika Hývlová, objednání sezení',
    url: 'https://www.cestasvetla.eu/reservation',
    image: null,
  },
  contact: {
    title: 'Kontakt – Pošli mi zprávu',
    description:
      'Máte otázky ohledně Theta Healing? Kontaktujte mě prostřednictvím formuláře nebo sociálních sítí. Ráda vám pomohu na cestě ke změně a harmonii.',
    keywords:
      'kontakt, Theta Healing, Veronika Hývlová, otázky, formulář, sociální sítě, pomoc, spirituální růst',
    url: 'https://www.cestasvetla.eu/contact',
    image: null,
  },
  certification: {
    title: 'Certifikace – Theta Healing certifikace a kvalifikace',
    description:
      'Zjistěte více o mých certifikacích a kvalifikacích v oblasti Theta Healing. Důvěřujte odbornosti a zkušenostem na cestě k vaší změně a harmonii.',
    keywords:
      'certifikace, Theta Healing, Veronika Hývlová, kvalifikace, odbornost, spirituální růst, energetické léčení',
    url: 'https://www.cestasvetla.eu/certification',
    image: null,
  },
  gdpr: {
    title: 'Ochrana osobních údajů a politika Cookies | Cesta Světla',
    description:
      'Zjistěte, jak chráníme vaše osobní údaje v souladu s GDPR a jak fungují cookies na našem webu. Přečtěte si naše zásady ochrany osobních údajů a pravidla pro používání cookies.',
    keywords:
      'GDPR, ochrana osobních údajů, politika cookies, soukromí, zpracování dat, osobní údaje, bezpečnost, Cesta Světla',
    url: 'https://www.cestasvetla.eu/gdpr',
    image: null,
  },
  contract: {
    title: 'Obchodní podmínky | Cesta Světla',
    description:
      'Přečtěte si obchodní podmínky pro produkty a služby Cesty Světla. Informace o objednávkách, platbách, dodání, reklamacích a ochraně autorských práv.',
    keywords:
      'obchodní podmínky, podmínky nákupu, objednávky, platby, reklamační řád, ochrana autorských práv, Cesta Světla',
    url: 'https://www.cestasvetla.eu/contract',
    image: null,
  },
  qa: {
    title: 'Theta Healing - Často Kladené Otázky (FAQ)',
    description:
      'Zjisti vše o Theta Healingu a jak probíhá sezení. Odpovědi na často kladené otázky ohledně online sezení, přípravy, trvání a dalších informací.',
    keywords:
      'Theta Healing, FAQ, online sezení, příprava na sezení, jak probíhá sezení, otázky a odpovědi, energetické léčení, meditace, zdraví, duševní pohoda, léčebné techniky',
    url: 'https://www.cestasvetla.eu/articles/qa',
    image: null,
  },
  theta: {
    title:
      'Theta Healing: Jak ti tato metoda může pomoci dosáhnout cílů a zbavit se bloků',
    description:
      'Zjisti, co je Theta Healing a jak tato metoda energetického léčení může pomoci uvolnit bloky, zvýšit tvůj úspěch a léčit tělo i duši. Sezení probíhají online, zaměřují se na pozitivní změny a uvolnění v tvém životě.',
    keywords:
      'Theta Healing, energetické léčení, meditace, bloky, cíl, osobní rozvoj, Stvořitel, léčení těla, duševní zdraví, pozitivní změny, online sezení, Vianna Stibal, odstranění negativních přesvědčení',
    url: 'https://www.cestasvetla.eu/articles/theta',
    image: null,
  },
  stvoritel: {
    title:
      'Energie Stvoření: Co je a jak ji můžeme využít pro transformaci života',
    description:
      'Energie stvoření je univerzální síla, která prostupuje vším, co existuje. Objev, jak tuto energii vnímat, jak ovlivňuje tvůj život a jak ji využít k pozitivním změnám, meditacím a uzdravení.',
    keywords:
      'energie stvoření, univerzální síla, vibrace, meditace, transformace, energetické cvičení, zákon přitažlivosti, pozitivní změny, Theta healing, intuice, duchovní praxe, Prána, Tao, Čchi',
    url: 'https://www.cestasvetla.eu/articles/stvoritel',
    image: null,
  },
  prubeh: {
    title: 'Online Sezení Theta Healing: Jak probíhá',
    description:
      'Prožij online sezení Theta Healing, kde tě provedu meditací k propojení se Stvořitelem a léčení emocionálních bloků. Společně objevíme kořenová přesvědčení a nahradíme je pozitivními změnami. Laskavý přístup a individuální péče.',
    keywords:
      'online sezení, Theta Healing, meditace, propojení se Stvořitelem, léčení emocí, energetické léčení, pozitivní změny, kořenová přesvědčení, emocionální bloky, bezpodmínečná láska, energetická terapie, uzdravení, vnitřní klid',
    url: 'https://www.cestasvetla.eu/articles/prubeh',
    image: null,
  },
  thanks: {
    title:
      'Děkujeme za důvěru – Objevte více o Theta Healing a dalších tématech',
    description:
      'Děkujeme za vaši důvěru. Pokračujte v prozkoumávání našich článků a zdrojů o Theta Healing a dalších tématech, které vám pomohou na vaší cestě osobního růstu a uzdravení.',
    keywords:
      'děkujeme za důvěru, Theta Healing, osobní růst, uzdravení, články o spiritualitě, meditace, energetické léčení, osobní rozvoj, články o zdraví, pokračujte v učení',
    url: 'https://www.cestasvetla.eu/thankyou',
    image: null,
  },
  barter: {
    title: 'Barterová spolupráce - Theta Healing sezení | Cesta Světla',
    description:
      'Domluv se na barterové spolupráci a zažij Theta Healing sezení. Nabízím varianty 60 a 90 minut, které ti pomohou objevit nový pohled na život. Přijď si vyzkoušet sílu Theta Healingu ještě dnes!',
    keywords:
      'barterová spolupráce, Theta Healing, sezení 60 minut, sezení 90 minut, léčení, meditace, osobní rozvoj, barter výměna, spolupráce',
    url: 'https://www.cestasvetla.eu/barter',
    image: null,
  },
  osamelost: {
    title: 'Jak najít vnitřní sílu v okamžicích osamělosti',
    description:
      'Nauč se najít vnitřní sílu v náročných chvílích a přijmout osamělost jako příležitost k osobnímu růstu. Tento článek ti ukáže, jak se vypořádat s pocity osamění, izolace, zaměřit se na sebe a objevit v sobě oporu. Přijetí osamělosti je prvním krokem k tomu, jak využít tuto zkušenost k nalezení vnitřní rovnováhy. Když si uděláš čas pro sebe, zjistíš, co tě naplňuje a co je pro tebe skutečně důležité. Tento proces ti pomůže zlepšit vztah k sobě a objevit svou vnitřní sílu. Buď k sobě laskavá a dej si čas na sebepoznání. Objev, jak se osamělost může stát cestou k hlubšímu porozumění a sebelásce.',
    keywords:
      'vnitřní síla, náročné chvíle, osamělost, osobní růst, pocity osamění, izolace, zaměřit se na sebe, vnitřní rovnováha, čas pro sebe, naplnění, důležité hodnoty, vztah k sobě, sebepoznání, sebeláska, opora, hlubší porozumění, přijmutí osamělosti, vnitřní síla, osobní rozvoj, vnitřní růst',
    url: 'https://www.cestasvetla.eu/articles/osamelost',
    image: null,
  },
  opora: {
    title: 'Buď svou vlastní oporou',
    description:
      'Nauč se najít oporu sama v sobě a buduj pevné základy pro své sebevědomí. Tento článek ti ukáže, jak se zaměřit na empatii k sobě a přestat se soudit. Začni si vážit toho, kým jsi, a už nehledej podporu pouze u ostatních. Připomínej si, co všechno jsi už dokázala, a přestaň se obviňovat za své pocity. Uč se být v přítomnosti a pozorovat své myšlenky a emoce bez hodnocení. Sebevědomí je dovednost, kterou si můžeš osvojit krok za krokem. Obklop se pozitivními vlivy a postupně si buduj svou vnitřní sílu.',
    keywords:
      'opora v sobě, sebevědomí, empatie k sobě, přestat se soudit, vděčnost, vnitřní síla, pozitivní myšlení, sebeúcta, přítomnost, pozorování emocí, sebepoznání, osobní růst, pozitivní vlivy, vnitřní rovnováha, budování sebevědomí, překonávání obviňování, sebeláska, pozitivní změna, osobní rozvoj, vděčnost k sobě, sebereflexe, krok za krokem, zlepšení sebevědomí, buď oporou',
    url: 'https://www.cestasvetla.eu/articles/opora',
    image: null,
  },
  oceneni: {
    title: 'Jak si najít to, za co se mohu ocenit',
    description:
      'Nauč se najít důvody, proč se ocenit, a objev svou vnitřní sílu. Nauč se, jak si vážit všeho, co už jsi zvládla, a jak si všímat svých úspěchů a malých vítězství. Ať už jde o zvládnuté výzvy, pozitivní zpětnou vazbu od ostatních nebo radost z každodenních činností, každý okamžik je důkazem tvé síly. Zaměř se na vděčnost k sobě, reflektuj svůj den a uvědom si, co všechno jsi už překonala. Každý malý krok je krokem k větší sebelásce a sebereflexi.',
    keywords:
      'ocenění sebe, vděčnost, sebeúcta, osobní růst, sebevědomí, vnitřní síla, překonání výzev, pozitivní zpětná vazba, malá vítězství, sebereflexe, radost, talent, denní úspěchy, seberealizace, pozitivní návyky, osobní úspěchy, ocenění za malé kroky, vděčnost k sobě, překonání obtíží, sebereflexe, sebeláska, jak se ocenit',
    url: 'https://www.cestasvetla.eu/articles/oceneni',
    image: null,
  },
  pozitivita: {
    title: '8 tipů, jak myslet pozitivně v těžké situaci',
    description:
      'Nauč se myslet pozitivně i v těžkých situacích. Zjisti, jak se zaměřit na to, co je v tvém životě pozitivního, jak si dovolovat prožívat emoce, a jak přetvářet negativní myšlenky na pozitivní. Získej nadhled, buď trpělivá a obklop se lidmi, kteří tě podporují. S těmito kroky se naučíš, jak si udržet pozitivní postoj a jak se postupně dostat z náročných okamžiků s vděčností a optimismem. Pozitivní myšlení je dovednost, kterou se můžeš naučit a která ti přinese vnitřní sílu. Chce to ale čas a trénink.',
    keywords:
      'pozitivní myšlení, těžké situace, vděčnost, sebeúcta, nadhled, trpělivost, změna myšlení, emocí, pozitivní energie, podpora, pozitivní lidé, osobní růst, radost, vnitřní síla, přetváření myšlenek, srovnávání se, životní výzvy, sebereflexe, porovnávání se',
    url: 'https://www.cestasvetla.eu/articles/pozitivita',
    image: null,
  },
  empatie: {
    title: 'V čem se liší empatie sama k sobě od sebelítosti?',
    description:
      'Empatie sama k sobě a sebelítost jsou dva různé přístupy, jak se postavit k těžkostem života. Empatie ti pomáhá růst, přijímat své emoce a hledat způsoby, jak se podpořit a zlepšit. Naopak sebelítost tě drží v negativních myšlenkách, zaměřuje se na to, co ti chybí, a může tě izolovat. Tento článek ti ukáže, jak rozpoznat rozdíl mezi těmito pocity a jak si vybrat soucit místo lítosti. Naučíš se, jak se podpořit v těžkých chvílích, respektovat své hranice a stát se svou vlastní oporou.',
    keywords:
      'empatie sama k sobě, sebelítost, soucit, vnitřní síla, osobní růst, podpora, pozitivní myšlení, hranice, emocionální zdraví, sebehodnota, sebeúcta, sebepodpora, zvládání emocí, sebereflexe, překonávání těžkostí, vděčnost, zlepšení, negativní myšlenky, empatie, soucit',
    url: 'https://www.cestasvetla.eu/articles/empatie',
    image: null,
  },

  prehlceni: {
    title: 'Přehlcení z práce, co s tím?',
    description:
      'Přehlcení z práce je problém, který může ovlivnit tvůj každodenní život a pohodu. Tento článek ti ukáže, jak si přiznat, že se necítíš dobře, jak si dopřát prostor pro sebereflexi a jak postupně najít rovnováhu mezi prací a osobním životem. S pomocí několika jednoduchých kroků se naučíš, jak si obnovit radost ze své práce, najít ztracený smysl a přizpůsobit si pracovní život podle svých hodnot a potřeb. Přestaň se cítit přetížená a začni hledat způsoby, jak mít pracovní život, který tě naplňuje.',
    keywords:
      'přehlcení z práce,přetížení práce,jak se zbavit stresu v práci,vyhoření v práci,jak najít rovnováhu mezi prací a osobním životem,jak si najít radost v práci,ztráta sebevědomí v práci,pracovní stres a co s tím,přizpůsobení práce vlastním potřebám,sebereflexe v práci,jak se zklidnit při práci,návod na práci, která tě baví,jak se vyhnout pracovnímu vyhoření',
    url: 'https://www.cestasvetla.eu/articles/prehlceni',
    image: null,
  },

  prizpusobeni: {
    title:
      'Když se přizpůsobuješ příliš: Jak znovu najít sebe v nevyhovující práci (Přetížení práce)',
    description:
      'Pokud se cítíš přepracovaná a ztrácíš sebe sama ve své práci, možná se přizpůsobuješ příliš. Tento článek ti pomůže zjistit, jak znovu najít rovnováhu a obnovit autenticitu v pracovním životě. Nauč se, jak nastavit hranice, hodnotit svou situaci a dělat malé změny, které ti pomohou najít spokojenost v práci, která ti vyhovuje.',
    keywords:
      'přepracování, přetížení práce, ztráta sebe sama v práci, přizpůsobování se, pracovní hranice, jak nastavit hranice v práci, jak najít rovnováhu v práci, změna v práci, frustrace z práce, ztracená autenticita, jak obnovit radost v práci, práce, která tě naplňuje, pracovní stres, konzultace pro pracovní problém, osobní hranice v práci',
    url: 'https://www.cestasvetla.eu/articles/prizpusobeni',
    image: null,
  },
  //articlesID
};

const SEO: React.FC<SEOProps> = ({ page }) => {
  const seo = SEOData[page];
  return (
    <Helmet>
      {/* Basic Meta Tags */}
      <title>{seo.title}</title>
      <meta name='description' content={seo.description} />
      <meta name='author' content='Cesta Světla' />
      {seo.keywords && <meta name='keywords' content={seo.keywords} />}

      {/* Canonical Link */}
      <link rel='canonical' href={seo.url} />

      {/* Open Graph / Facebook */}
      <meta property='og:title' content={seo.title} />
      <meta property='og:description' content={seo.description} />
      {seo.image && <meta property='og:image' content={seo.image} />}
      <meta property='og:url' content={seo.url} />
      <meta property='og:type' content='website' />

      {/* Twitter */}
      <meta name='twitter:card' content='summary_large_image' />
      <meta name='twitter:title' content={seo.title} />
      <meta name='twitter:description' content={seo.description} />
      {seo.image && <meta name='twitter:image' content={seo.image} />}

      {/* Structured Data */}
      <script type='application/ld+json'>
        {JSON.stringify({
          '@context': 'http://schema.org',
          '@type': 'Organization',
          url: seo.url,
          name: seo.title,
          sameAs: [
            'https://www.facebook.com/profile.php?id=61566270399078&locale=cs_CZ',
            'https://www.instagram.com/cestasvetla/',
          ],
        })}
      </script>
    </Helmet>
  );
};

export default SEO;

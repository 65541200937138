import React, { useEffect } from 'react';
import './index.css';
import { Route, Routes, useLocation } from 'react-router-dom';
import Contact from './pages/Contact';
import NewHeader from './components/NewHeader';
import NewFooter from './components/NewFooter';
import FeedbackPage from './pages/FeedbackPage';
import ThetaPage from './pages/BlogRootPage';
import HomePage from './pages/HomePage';
import CertificationPage from './pages/CertificationPage';
import ReservationPageRev from './pages/ReservationPageRev';
import Article from './components/Article';
import AboutMe from './pages/AboutMe';
import GDPRPage from './pages/GDPRPage';
import ContractPage from './pages/ContractPage';
import ScrollToTop from './components/ScrollToTop';
import ReservationPage from './pages/ReservationPage';
import SEO from './components/SEO';
import ThankYouPage from './pages/ThankYouPage';
import ReactGA from 'react-ga4';
import BarterPage from './pages/BarterPage';
import bgImage from './util/img/DSC_6049.webp';
import Redirect from './pages/Redirect';
import NotFound from './pages/NotFound';
import Ribbon from './components/Ribbon';
import CookieConsent from './components/CookieConsent';

const App: React.FC = () => {
  const location = useLocation();
  const displayRibbon = false;

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: location.pathname + location.search,
    });
  }, [location]);

  return (
    <div
      className='App flex flex-col min-h-screen bg-cover bg-center bg-fixed '
      style={{
        backgroundImage: `url(${bgImage})`,
        backgroundAttachment: 'fixed',
      }}
    >
      <CookieConsent />
      <NewHeader />
      <main className='flex flex-grow'>
        <ScrollToTop />
        <Routes>
          <Route
            path='/'
            element={
              <>
                <SEO page='home' />
                {displayRibbon && <Ribbon />}
                <HomePage />
              </>
            }
          />
          <Route
            path='/domu'
            element={
              <>
                <SEO page='home' />
                {displayRibbon && <Ribbon />}
                <HomePage />
              </>
            }
          />
          <Route
            path='/about'
            element={
              <>
                <SEO page='about' />
                {displayRibbon && <Ribbon />}
                <AboutMe />
              </>
            }
          />
          <Route
            path='/o-mne'
            element={
              <>
                <SEO page='about' />
                {displayRibbon && <Ribbon />}
                <AboutMe />
              </>
            }
          />
          <Route
            path='/articles'
            element={
              <>
                <SEO page='articles' />
                <ThetaPage />
              </>
            }
          />
          <Route
            path='/clanky'
            element={
              <>
                <SEO page='articles' />
                <ThetaPage />
              </>
            }
          />
          <Route path='/articles/:id' element={<Article />} />
          <Route path='/clanky/:id' element={<Article />} />
          <Route
            path='/feedback'
            element={
              <>
                <SEO page='feedback' />
                <FeedbackPage />
              </>
            }
          />
          <Route
            path='/zpetna-vazba'
            element={
              <>
                <SEO page='feedback' />
                <FeedbackPage />
              </>
            }
          />
          <Route
            path='/reservation'
            element={
              <>
                <SEO page='reservation' />
                {displayRibbon && <Ribbon />}
                <ReservationPage />
              </>
            }
          />
          <Route
            path='/rezervace'
            element={
              <>
                <SEO page='reservation' />
                {displayRibbon && <Ribbon />}
                <ReservationPage />
              </>
            }
          />
          <Route path='/res' element={<ReservationPageRev />} />
          <Route
            path='/contact'
            element={
              <>
                <SEO page='contact' />
                <Contact />
              </>
            }
          />
          <Route
            path='/kontakt'
            element={
              <>
                <SEO page='contact' />
                <Contact />
              </>
            }
          />
          <Route
            path='/certification'
            element={
              <>
                <SEO page='certification' />
                <CertificationPage />
              </>
            }
          />
          <Route
            path='/certifikace'
            element={
              <>
                <SEO page='certification' />
                <CertificationPage />
              </>
            }
          />
          <Route
            path='/gdpr'
            element={
              <>
                <SEO page='gdpr' />
                <GDPRPage />
              </>
            }
          />
          <Route
            path='/contract'
            element={
              <>
                <SEO page='contract' />
                <ContractPage />
              </>
            }
          />
          <Route
            path='/thankyou'
            element={
              <>
                <SEO page='thanks' />
                <ThankYouPage />
              </>
            }
          />
          <Route
            path='/dekuji'
            element={
              <>
                <SEO page='thanks' />
                <ThankYouPage />
              </>
            }
          />
          <Route
            path='/barter'
            element={
              <>
                <SEO page='barter' />
                <BarterPage />
              </>
            }
          />
          <Route path='/redirect' element={<Redirect />} />
          <Route path='*' element={<NotFound />} />{' '}
          {/* Catch-all for undefined routes */}
        </Routes>
      </main>
      <NewFooter />
    </div>
  );
};

export default App;

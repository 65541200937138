import { useState, useEffect } from 'react';

const CookieConsent = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    if (!consent) {
      setIsVisible(true);
    }
  }, []);

  const handleAccept = () => {
    // Uloží souhlas do localStorage
    localStorage.setItem('cookieConsent', 'accepted');
    setIsVisible(false);
  };

  const handleDecline = () => {
    // Uloží nesouhlas a zakáže cookies
    localStorage.setItem('cookieConsent', 'declined');
    clearCookies();
    setIsVisible(false);
  };

  const clearCookies = () => {
    document.cookie.split(';').forEach((cookie) => {
      const name = cookie.split('=')[0].trim();
      document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 UTC;path=/`;
    });
  };

  if (!isVisible) return null;

  return (
    <div className='fixed bottom-0 left-0 w-full bg-gray-800 text-white p-4 z-50 flex justify-between items-center'>
      <p className='text-sm'>
        Tento web používá cookies ke zlepšení vašich služeb. Pokračováním
        souhlasíte s našimi{' '}
        <a href='/gdpr' className='underline'>
          zásadami ochrany osobních údajů
        </a>
        .
      </p>
      <div className='space-x-2'>
        <button
          className='bg-green-500 px-4 py-2 rounded text-sm'
          onClick={handleAccept}
        >
          Přijmout
        </button>
        <button
          className='bg-red-500 px-4 py-2 rounded text-sm'
          onClick={handleDecline}
        >
          Odmítnout
        </button>
      </div>
    </div>
  );
};

export default CookieConsent;

import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router-dom';
import NavigationLink from './navigation/NavigationLink';

const MobileNavigationBar: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState('DOMŮ');

  const location = useLocation();

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const linkClicked = (linkString: string) => {
    setSelectedTab(linkString);
    if (isOpen) setIsOpen(false);
  };

  useEffect(() => {
    console.log(location.pathname);
    if (
      (location.pathname === '/' ||
        location.pathname.includes('domu') ||
        location.pathname.includes('home')) &&
      selectedTab !== 'DOMŮ'
    ) {
      setSelectedTab('DOMŮ');
    }
    if (
      (location.pathname.includes('about') ||
        location.pathname.includes('o-mne')) &&
      selectedTab !== 'O MNĚ'
    ) {
      setSelectedTab('O MNĚ');
    }
    if (
      (location.pathname.includes('articles') ||
        location.pathname.includes('clanky')) &&
      selectedTab !== 'ČLÁNKY'
    ) {
      setSelectedTab('ČLÁNKY');
    }
    if (
      (location.pathname.includes('certifikace') ||
        location.pathname.includes('certification')) &&
      selectedTab !== 'CERTIFIKACE'
    ) {
      setSelectedTab('CERTIFIKACE');
    }
    if (
      (location.pathname.includes('rezervace') ||
        location.pathname.includes('reservation')) &&
      selectedTab !== 'REZERVACE'
    ) {
      setSelectedTab('REZERVACE');
    }
    if (
      (location.pathname.includes('feedback') ||
        location.pathname.includes('zpetna-vazba')) &&
      selectedTab !== 'RECENZE'
    ) {
      setSelectedTab('RECENZE');
    }
    if (
      (location.pathname.includes('contact') ||
        location.pathname.includes('kontakt')) &&
      selectedTab !== 'KONTAKT'
    ) {
      setSelectedTab('KONTAKT');
    }
  }, [location]);

  return (
    <div className='bg-custom-test fixed w-full top-0 z-50 lg:hidden'>
      <div className='flex justify-between items-center p-4 shadow-lg shadow-amber-900'>
        <div className='flex items-center justify-center w-full'>
          <button
            onClick={toggleMenu}
            className='text-amber-900 hover:text-amber-900 transition duration-300 ease-in-out focus:outline-none flex items-center justify-center'
          >
            <FontAwesomeIcon
              className='pl-4 pt-5'
              icon={isOpen ? faTimes : faBars}
              size='xl'
            />
            <span className="text-lg text-amber-900 py-1 px-5 font-extrabold font-['MyCustomFont2']">
              {selectedTab}
            </span>
          </button>
        </div>
      </div>
      {isOpen && (
        <nav className='bg-white/10 shadow-lg shadow-amber-900 transition duration-300 ease-in-out'>
          <ul className='flex flex-col items-center space-y-4 p-4'>
            <NavigationLink
              dark
              navTo={'/'}
              navString={'DOMŮ'}
              navStringCall={linkClicked}
            />
            <NavigationLink
              dark
              navTo={'/o-mne'}
              navString={'O MNĚ'}
              navStringCall={linkClicked}
            />
            <NavigationLink
              dark
              navTo={'/clanky'}
              navString={'ČLÁNKY'}
              navStringCall={linkClicked}
            />
            <NavigationLink
              dark
              navTo={'/certifikace'}
              navString={'CERTIFIKACE'}
              navStringCall={linkClicked}
            />
            <NavigationLink
              dark
              navTo={'/rezervace'}
              navString={'REZERVACE'}
              navStringCall={linkClicked}
            />
            <NavigationLink
              dark
              navTo={'/zpetna-vazba'}
              navString={'RECENZE'}
              navStringCall={linkClicked}
            />
            <NavigationLink
              dark
              navTo={'/kontakt'}
              navString={'KONTAKT'}
              navStringCall={linkClicked}
            />
          </ul>
        </nav>
      )}
    </div>
  );
};

export default MobileNavigationBar;

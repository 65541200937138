import React from 'react';
import './index.css';
import App from './App';
import { AppProvider } from './context/AppContext';
import { BrowserRouter } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faInstagram, faFacebook } from '@fortawesome/free-brands-svg-icons';
import { HelmetProvider } from 'react-helmet-async';
import ReactGA from 'react-ga4';
import ReactDOM from 'react-dom/client';

library.add(faInstagram, faFacebook);
const initializeAnalytics = () => {
  ReactGA.initialize('G-J0NY7ECRBM');
};
if (localStorage.getItem('cookieConsent') === 'accepted') {
  initializeAnalytics();
}

document.addEventListener('contextmenu', (e: MouseEvent) => {
  const target = e.target as HTMLElement;
  if (target.tagName === 'IMG') {
    e.preventDefault();
  }
});

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <HelmetProvider>
      <AppProvider>
        <BrowserRouter basename='/'>
          <App />
        </BrowserRouter>
      </AppProvider>
    </HelmetProvider>
  </React.StrictMode>
);

import React, { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ArticleTitle from './ArticleTitle';
import mammoth from 'mammoth';
import AuthorSign from './AuthorSign';
import QAContent from './articles/QAContent';
import StyledButton from './StyledButton';
import ReactDOM from 'react-dom';
import NextArticles from './NextArticles';
import Subscribe from './Subscribe';
import img2 from '../util/img/sm_DSC_6032.webp';
import SEO from './SEO';
import {
  handleNavigateContact,
  handleNavigateToAbout,
  handleNavigateToOceneni,
  handleNavigateToOpora,
  handleNavigateToPozitivita,
  handleNavigateToPrizpusobeni,
  handleNavigateToPrubeh,
  handleNavigateToQA,
  handleNavigateToReservation,
  handleNavigateToStvoritel,
  handleNavigateToThetaArticle,
  handleNavigateToWhyThetaArticle,
} from '../helpers/navigations';
import { useAppContext } from '../context/AppContext';
import WhyThetaContent from './articles/WhyThetaContent';

const Article: React.FC = () => {
  const { id } = useParams<{ id: string }>();

  const [article, setArticle] = useState<any>({});
  const [textData, setTextData] = useState<string>('');
  const { setActiveMeeting } = useAppContext();
  const contentRef = useRef(null);
  const navigate = useNavigate();

  const getSEO = (idStr: string) => {
    if (idStr === 'qa') {
      return idStr;
    }
    if (idStr === 'theta') {
      return idStr;
    }
    if (idStr === 'stvoritel') {
      return idStr;
    }
    if (idStr === 'prubeh') {
      return idStr;
    } else {
      return 'articles';
    }
  };

  const { hash } = useLocation();

  useEffect(() => {
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [hash, textData]);

  useEffect(() => {
    fetch('/content-data/articles/articles.json')
      .then((response) => response.json())
      .then((data) => {
        const article = data.articles.find(
          (article: { id: string }) => article.id === id
        );
        setArticle(article);
      })
      .catch((error) => console.error('Error loading feedback data:', error));
  }, [id]);

  useEffect(() => {
    fetch('/content-data/articles/txt/' + article.content)
      .then((response) => {
        console.log(response);
        return response.arrayBuffer();
      })
      .then((arrayBuffer) => {
        // Convert the DOCX binary data to HTML
        return mammoth.convertToHtml(
          { arrayBuffer },
          {
            styleMap: [
              "p[style-name='Heading 1'] => h1.mammoth-heading1:fresh",
              "p[style-name='Heading 2'] => h2.mammoth-heading2:fresh",
              "p[style-name='Heading 4'] => span.mammoth-heading3:fresh",
              "p[style-name='Normal'] => p:fresh", // Handle normal paragraphs
              "p[style-name='No Spacing'] => p:fresh", // Handle paragraphs without spacing
              'b => strong', // Convert bold text to strong
              'i => em', // Convert italic text to em
              'u => u', // Convert underlined text to <u>
              "p[style-name='Hyperlink'] => a", // Handle hyperlinks
              'r => br', // Convert hard line breaks
              'ul => ul.mammoth-list', // Add class to <ul> for custom styling
              'li => li.mammoth-item', // Add class to <li> for square styling
            ],
          }
        );
      })
      .then((result) => {
        let modifiedHtml = result.value; // Extracted HTML
        console.log(modifiedHtml);
        if (id === 'prizpusobeni') {
          modifiedHtml = modifiedHtml.replace(
            '(odkaz na konzultací)',
            "<span id='PRI1-placeholder'></span>" // Placeholder where the button will go
          );
        }
        if (id === 'prehlceni') {
          modifiedHtml = modifiedHtml.replace(
            '(foto1)',
            "<div id='imgPRE1-placeholder'></div>" // Placeholder where the button will go
          );
          modifiedHtml = modifiedHtml.replace(
            '(foto2)',
            "<div id='imgPRE2-placeholder'></div>" // Placeholder where the button will go
          );
          modifiedHtml = modifiedHtml.replace(
            '(tento)',
            "<span id='PRE1-placeholder'></span>" // Placeholder where the button will go
          );
        }
        if (id === 'empatie') {
          modifiedHtml = modifiedHtml.replace(
            '(fotoE1)',
            "<div id='imgE1-placeholder'></div>" // Placeholder where the button will go
          );
          modifiedHtml = modifiedHtml.replace(
            '(8 tipů jak myslet pozitivně)',
            "<span id='E1-placeholder'></span>" // Placeholder where the button will go
          );
          modifiedHtml = modifiedHtml.replace(
            '(Aby druzí respektovali moje hranice)',
            "<span id='E2-placeholder'></span>" // Placeholder where the button will go
          );
          modifiedHtml = modifiedHtml.replaceAll(
            '(X)',
            "<span class='font-[1000] pl-1 pr-1 text-black'> ✖ </span>" // Placeholder where the button will go
          );
        }
        if (id === 'pozitivita') {
          modifiedHtml = modifiedHtml.replace(
            '(fotoP1)',
            "<div id='imgP1-placeholder'></div>" // Placeholder where the button will go
          );
          modifiedHtml = modifiedHtml.replace(
            '(fotoP2)',
            "<div id='imgP2-placeholder'></div>" // Placeholder where the button will go
          );
        }
        if (id === 'oceneni') {
          modifiedHtml = modifiedHtml.replace(
            '(fotoOc1)',
            "<div id='imgOc1-placeholder'></div>" // Placeholder where the button will go
          );
          modifiedHtml = modifiedHtml.replace(
            '(Buď svou vlastní oporou)',
            "<span id='Oc1-placeholder'></span>" // Placeholder where the button will go
          );
        }
        if (id === 'opora') {
          modifiedHtml = modifiedHtml.replace(
            '(fotoO1)',
            "<div id='imgO1-placeholder'></div>" // Placeholder where the button will go
          );
          modifiedHtml = modifiedHtml.replace(
            '(fotoO2)',
            "<div id='imgO2-placeholder'></div>" // Placeholder where the button will go
          );
          modifiedHtml = modifiedHtml.replace(
            '(odkaz1)',
            "<span id='O1-placeholder'></span>" // Placeholder where the button will go
          );
          modifiedHtml = modifiedHtml.replace(
            '(odkaz2)',
            "<span id='O2-placeholder'></span>" // Placeholder where the button will go
          );
        }
        if (id === 'osamelost') {
          modifiedHtml = modifiedHtml.replace(
            '(img1)',
            "<div id='img2-placeholder'></div>" // Placeholder where the button will go
          );
          modifiedHtml = modifiedHtml.replace(
            '(ukážu jak na to)',
            "<span id='button-placeholder7'></span>" // Placeholder where the button will go
          );
          modifiedHtml = modifiedHtml.replace(
            '(Buď svou vlastní oporou….)',
            "<span id='button-placeholder8'></span>" // Placeholder where the button will go
          );
        }
        if (id === 'proc-theta') {
          modifiedHtml = modifiedHtml.replace(
            '(links)',
            "<div id='button-placeholder3'></div>" // Placeholder where the button will go
          );
        }
        if (id === 'stvoritel') {
          modifiedHtml = modifiedHtml.replace(
            '(fotoS1)',
            "<div id='img4-placeholder'></div>" // Placeholder where the button will go
          );
          modifiedHtml = modifiedHtml.replace(
            '(fotoS2)',
            "<div id='img5-placeholder'></div>" // Placeholder where the button will go
          );
          modifiedHtml = modifiedHtml.replace(
            '(zaver)',
            "<div id='button-placeholder6'></div>" // Placeholder where the button will go
          );
        }
        if (id === 'prubeh') {
          modifiedHtml = modifiedHtml.replace(
            '(medodou Theta Healing)',
            "<span id='button-placeholder5'></span>" // Placeholder where the button will go
          );
          // Inject a placeholder for the button
          modifiedHtml = modifiedHtml.replace(
            '(odkaz na rezervuj si sezení se mnou)',
            "<div id='button-placeholder'></div>" // Placeholder where the button will go
          );
          modifiedHtml = modifiedHtml.replace(
            '(foto1)',
            "<div id='img1-placeholder'></div>" // Placeholder where the button will go
          );
        }
        if (id === 'theta') {
          // Inject a placeholder for the button
          modifiedHtml = modifiedHtml.replace(
            '(Více o mém příběhu zde)',
            "<div id='button-placeholder2'></div>" // Placeholder where the button will go
          );
          modifiedHtml = modifiedHtml.replace(
            '(odkaz na rezervuj si sezení se mnou)',
            "<div id='button-placeholder'></div>" // Placeholder where the button will go
          );
          modifiedHtml = modifiedHtml.replace(
            '(svoreni)',
            "<div id='button-placeholder4'></div>" // Placeholder where the button will go
          );
          modifiedHtml = modifiedHtml.replace(
            '(fotoTheta)',
            "<div id='img3-placeholder'></div>" // Placeholder where the button will go
          );
          modifiedHtml = modifiedHtml.replaceAll(
            '<ul>',
            '<ul class="mammoth-list">' // Placeholder where the button will go
          );
          modifiedHtml = modifiedHtml.replaceAll(
            '<li>',
            '<li class="mammoth-item">' // Placeholder where the button will go
          );
        }
        setTextData(modifiedHtml); // Set HTML into state
      })
      .catch((error) => console.error('Error loading document:', error));
  }, [article, id]);

  useEffect(() => {
    // After the HTML is rendered, find the placeholder and inject the button
    if (
      (id === 'theta' ||
        id === 'opora' ||
        id === 'empatie' ||
        id === 'pozitivita' ||
        id === 'oceneni' ||
        id === 'prubeh' ||
        id === 'proc-theta' ||
        id === 'osamelost' ||
        id === 'prehlceni' ||
        id === 'prizpusobeni' ||
        id === 'stvoritel') &&
      contentRef.current
    ) {
      const placeholder = document.getElementById('button-placeholder');
      const placeholder1 = document.getElementById('img1-placeholder');
      const placeholderim2 = document.getElementById('img2-placeholder');
      const placeholderim3 = document.getElementById('img3-placeholder');
      const placeholderim4 = document.getElementById('img4-placeholder');
      const placeholderim5 = document.getElementById('img5-placeholder');
      const placeholder2 = document.getElementById('button-placeholder2');
      const placeholder3 = document.getElementById('button-placeholder3');
      const placeholder4 = document.getElementById('button-placeholder4');
      const placeholder5 = document.getElementById('button-placeholder5');
      const placeholder6 = document.getElementById('button-placeholder6');
      const placeholder7 = document.getElementById('button-placeholder7');
      const placeholder8 = document.getElementById('button-placeholder8');
      //Opora
      const imgO1placeholde = document.getElementById('imgO1-placeholder');
      const imgO2placeholder = document.getElementById('imgO2-placeholder');
      const o1placeholder = document.getElementById('O1-placeholder');
      const o2placeholder = document.getElementById('O2-placeholder');
      //oceneni
      const imgOc1placeholde = document.getElementById('imgOc1-placeholder');
      const oc1placeholder = document.getElementById('Oc1-placeholder');
      //pozitivita
      const imgP1placeholde = document.getElementById('imgP1-placeholder');
      const imgP2placeholde = document.getElementById('imgP2-placeholder');
      //empatie
      const imgE1placeholder = document.getElementById('imgE1-placeholder');
      const E1placeholder = document.getElementById('E1-placeholder');
      const E2placeholder = document.getElementById('E2-placeholder');
      //prehlceni
      const imgPRE1placeholder = document.getElementById('imgPRE1-placeholder');
      const imgPRE2placeholder = document.getElementById('imgPRE2-placeholder');
      const PRE1placeholder = document.getElementById('PRE1-placeholder');
      //prizpusobeni
      const PRI1placeholder = document.getElementById('PRI1-placeholder');

      if (PRI1placeholder) {
        // Inject the React button into the placeholder
        ReactDOM.render(
          <StyledButton
            handleClick={() => handleNavigateToReservation(navigate)}
            text='Rezervuj si konzultaci se mnou'
          />,
          PRI1placeholder
        );
      }

      if (imgPRE1placeholder) {
        // Inject the React button into the placeholder
        ReactDOM.render(
          <div className='flex h-64 overflow-hidden lg:h-80 items-center justify-center w-full'>
            <img
              loading='lazy'
              src={'/content-data/articles/img/prehlceni1.webp'}
              alt='Logo'
              className='w-full lg:w-auto h-60 lg:h-80 object-cover '
            />
          </div>,
          imgPRE1placeholder
        );
      }
      if (imgPRE2placeholder) {
        // Inject the React button into the placeholder
        ReactDOM.render(
          <div className='flex h-64 overflow-hidden lg:h-80 items-center justify-center w-full'>
            <img
              loading='lazy'
              src={'/content-data/articles/img/prehlceni2.webp'}
              alt='Logo'
              className='w-full lg:w-auto h-60 lg:h-80 object-cover '
            />
          </div>,
          imgPRE2placeholder
        );
      }
      if (PRE1placeholder) {
        // Inject the React button into the placeholder
        ReactDOM.render(
          <span
            className='inline font-[600] underline cursor-pointer text-amber-900'
            onClick={() => {
              handleNavigateToPrizpusobeni(navigate);
            }}
          >
            tento
          </span>,
          PRE1placeholder
        );
      }

      if (imgE1placeholder) {
        // Inject the React button into the placeholder
        ReactDOM.render(
          <div className='flex h-64 overflow-hidden lg:h-80 items-center justify-center w-full'>
            <img
              loading='lazy'
              src={'/content-data/articles/img/empatie2.webp'}
              alt='Logo'
              className='w-full lg:w-auto h-60 lg:h-80 object-cover '
            />
          </div>,
          imgE1placeholder
        );
      }
      if (E1placeholder) {
        // Inject the React button into the placeholder
        ReactDOM.render(
          <span
            className='inline font-[600] underline cursor-pointer text-amber-900'
            onClick={() => {
              handleNavigateToPozitivita(navigate);
            }}
          >
            tipy
          </span>,
          E1placeholder
        );
      }
      if (E2placeholder) {
        // Inject the React button into the placeholder
        ReactDOM.render(
          <span
            className='inline font-[600] underline cursor-pointer text-amber-900'
            onClick={() => {
              // handle(navigate);
            }}
          >
            Aby druzí respektovali moje hranice
          </span>,
          E2placeholder
        );
      }

      if (imgP1placeholde) {
        // Inject the React button into the placeholder
        ReactDOM.render(
          <div className='flex h-64 overflow-hidden lg:h-80 items-center justify-center w-full'>
            <img
              loading='lazy'
              src={'/content-data/articles/img/pozitivita1.webp'}
              alt='Logo'
              className='w-full lg:w-auto h-60 lg:h-80 object-cover '
            />
          </div>,
          imgP1placeholde
        );
      }
      if (imgP2placeholde) {
        // Inject the React button into the placeholder
        ReactDOM.render(
          <div className='flex h-64 overflow-hidden lg:h-80 items-center justify-center w-full'>
            <img
              loading='lazy'
              src={'/content-data/articles/img/pozitivita2.webp'}
              alt='Logo'
              className='w-full lg:w-auto h-60 lg:h-80 object-cover '
            />
          </div>,
          imgP2placeholde
        );
      }

      if (imgOc1placeholde) {
        // Inject the React button into the placeholder
        ReactDOM.render(
          <div className='flex h-64 overflow-hidden lg:h-80 items-center justify-center w-full'>
            <img
              loading='lazy'
              src={'/content-data/articles/img/oceneni.webp'}
              alt='Logo'
              className='w-full lg:w-auto h-60 lg:h-80 object-cover '
            />
          </div>,
          imgOc1placeholde
        );
      }
      if (oc1placeholder) {
        // Inject the React button into the placeholder
        ReactDOM.render(
          <span
            className='inline font-[600] underline cursor-pointer text-amber-900'
            onClick={() => {
              handleNavigateToOpora(navigate);
            }}
          >
            Buď svou vlastní oporou
          </span>,
          oc1placeholder
        );
      }
      if (imgO1placeholde) {
        // Inject the React button into the placeholder
        ReactDOM.render(
          <div className='flex h-64 overflow-hidden lg:h-80 items-center justify-center w-full'>
            <img
              loading='lazy'
              src={'/content-data/articles/img/opora2.webp'}
              alt='Logo'
              className='w-full lg:w-auto h-60 lg:h-80 object-cover '
            />
          </div>,
          imgO1placeholde
        );
      }
      if (imgO2placeholder) {
        // Inject the React button into the placeholder
        ReactDOM.render(
          <div className='flex h-64 overflow-hidden lg:h-80 items-center justify-center w-full'>
            <img
              loading='lazy'
              src={'/content-data/articles/img/opora1.webp'}
              alt='Logo'
              className='w-full lg:w-auto h-60 lg:h-80 object-cover '
            />
          </div>,
          imgO2placeholder
        );
      }
      if (o1placeholder) {
        // Inject the React button into the placeholder
        ReactDOM.render(
          <span
            className='inline font-[600] underline cursor-pointer text-amber-900'
            onClick={() => {
              handleNavigateToPozitivita(navigate);
            }}
          >
            8 tipů, jak myslet pozitivně v těžké situaci
          </span>,
          o1placeholder
        );
      }
      if (o2placeholder) {
        // Inject the React button into the placeholder
        ReactDOM.render(
          <span
            className='inline font-[600] underline cursor-pointer text-amber-900'
            onClick={() => {
              handleNavigateToOceneni(navigate);
            }}
          >
            Jak si najít to, za co se mohu ocenit
          </span>,
          o2placeholder
        );
      }

      if (placeholderim2 && id === 'osamelost') {
        // Inject the React button into the placeholder
        ReactDOM.render(
          <div className='flex h-64 overflow-hidden lg:h-80 items-center justify-center w-full'>
            <img
              loading='lazy'
              src={'/content-data/articles/img/osamelost.webp'}
              alt='Logo'
              className='w-full lg:w-auto h-60 lg:h-80 object-cover '
            />
          </div>,
          placeholderim2
        );
      }
      if (placeholder7) {
        // Inject the React button into the placeholder
        ReactDOM.render(
          <span
            className='inline font-[600] underline cursor-pointer text-amber-900'
            onClick={() => {
              handleNavigateToReservation(navigate);
            }}
          >
            ukážu jak na to
          </span>,
          placeholder7
        );
      }
      if (placeholder8) {
        // Inject the React button into the placeholder
        ReactDOM.render(
          <span
            className='inline font-[600] underline cursor-pointer text-amber-900'
            onClick={() => {
              handleNavigateToOpora(navigate);
            }}
          >
            Buď svou vlastní oporou.
          </span>,
          placeholder8
        );
      }

      if (placeholder && id === 'prubeh') {
        // Inject the React button into the placeholder
        ReactDOM.render(
          <div>
            <div className='h-px w-full bg-secondary-button-color mb-5' />
            <p>
              Máš nějaké otázky?
              <span
                className='font-[600] pl-1 underline cursor-pointer text-amber-900'
                onClick={() => {
                  handleNavigateContact(navigate);
                }}
              >
                Kontaktuj mě
              </span>{' '}
              a nebo se podívej jestli nenajdeš svojí odpověd{' '}
              <span
                className='font-[600] underline cursor-pointer text-amber-900'
                onClick={() => {
                  handleNavigateToQA(navigate);
                }}
              >
                zde
              </span>
              .
            </p>
            <section className='flex flex-col lg:flex-row w-full lg:space-x-6 space-y-3 lg:space-y-0 pt-6'>
              <StyledButton
                handleClick={() => handleNavigateToWhyThetaArticle(navigate)}
                text='Proč Theta healing?'
              />
              <StyledButton
                handleClick={() => handleNavigateToReservation(navigate)}
                text='Rezervace'
              />
            </section>
          </div>,
          placeholder
        );
      }
      if (placeholder6 && id === 'stvoritel') {
        // Inject the React button into the placeholder
        ReactDOM.render(
          <div>
            <div className='h-px w-full bg-secondary-button-color mb-5' />
            <p>
              Máš nějaké otázky?
              <span
                className='font-[600] pl-1 underline cursor-pointer text-amber-900'
                onClick={() => {
                  handleNavigateContact(navigate);
                }}
              >
                Kontaktuj mě
              </span>{' '}
              a nebo se podívej jestli nenajdeš svojí odpověd{' '}
              <span
                className='font-[600] underline cursor-pointer text-amber-900'
                onClick={() => {
                  handleNavigateToQA(navigate);
                }}
              >
                zde
              </span>
              .
            </p>
          </div>,
          placeholder6
        );
      }
      if (placeholder && id !== 'prubeh') {
        // Inject the React button into the placeholder
        ReactDOM.render(
          <StyledButton
            handleClick={() => handleNavigateToReservation(navigate)}
            text='Rezervuj si sezení se mnou'
          />,
          // <StyledButton
          //   handleClick={() => handleNavigateToReservation(navigate)}
          //   text='Rezervuj si sezení se mnou'
          // />
          placeholder
        );
      }
      if (placeholder2) {
        // Inject the React button into the placeholder
        ReactDOM.render(
          <StyledButton
            handleClick={() => {
              handleNavigateToAbout(navigate);
            }}
            text='Víc o mém příběhu'
          />,
          placeholder2
        );
      }
      if (placeholder5) {
        // Inject the React button into the placeholder
        ReactDOM.render(
          <span
            className='inline font-[600] underline cursor-pointer text-amber-900'
            onClick={() => {
              handleNavigateToThetaArticle(navigate);
            }}
          >
            metodou Theta Healing
          </span>,
          placeholder5
        );
      }
      if (placeholder4) {
        // Inject the React button into the placeholder
        ReactDOM.render(
          <>
            <div className='h-px w-full bg-secondary-button-color mb-5' />
            <p>
              Říkáš si, co je to ta energie, se kterou se v theta healingu
              pracuje? Potom se můžeš podívat na{' '}
              <span
                className='font-[600] underline cursor-pointer text-amber-900'
                onClick={() => {
                  handleNavigateToStvoritel(navigate);
                }}
              >
                tento
              </span>{' '}
              článek.{' '}
            </p>
          </>,
          placeholder4
        );
      }
      if (placeholder1) {
        // Inject the React button into the placeholder
        ReactDOM.render(
          <div className='flex h-64 overflow-hidden lg:h-80 items-center justify-center w-full'>
            <img
              loading='lazy'
              src={img2}
              alt='Logo'
              className='w-full lg:w-auto h-60 lg:h-80 object-cover '
            />
          </div>,
          placeholder1
        );
      }
      if (placeholderim3) {
        // Inject the React button into the placeholder
        ReactDOM.render(
          <div className='flex h-64 overflow-hidden lg:h-80 items-center justify-center w-full'>
            <img
              loading='lazy'
              src={'/content-data/articles/img/theta1.webp'}
              alt='Logo'
              className='w-full lg:w-auto h-60 lg:h-80 object-cover '
            />
          </div>,
          placeholderim3
        );
      }
      if (placeholderim4) {
        // Inject the React button into the placeholder
        ReactDOM.render(
          <div className='flex h-64 overflow-hidden lg:h-80 items-center justify-center w-full'>
            <img
              loading='lazy'
              src={'/content-data/articles/img/stvoritel1.webp'}
              alt='Logo'
              className='w-full lg:w-auto h-60 lg:h-80 object-cover '
            />
          </div>,
          placeholderim4
        );
      }
      if (placeholderim5) {
        // Inject the React button into the placeholder
        ReactDOM.render(
          <div className='flex h-64 overflow-hidden lg:h-80 items-center justify-center w-full'>
            <img
              loading='lazy'
              src={'/content-data/articles/img/stvoritel2.webp'}
              alt='Logo'
              className='w-full lg:w-auto h-60 lg:h-80 object-cover '
            />
          </div>,
          placeholderim5
        );
      }

      if (placeholder3) {
        ReactDOM.render(
          <div>
            <StyledButton
              handleClick={() => {
                setActiveMeeting('konzultace');
                handleNavigateToReservation(navigate);
              }}
              text='Chci Vyzkoušet 20 min zdarma'
            />
            <p
              className='pt-4 underline cursor-pointer font-[600] transition-all duration-300 ease-in-out hover:scale-[1.02]'
              onClick={() => handleNavigateToPrubeh(navigate)}
            >
              Průběh sezení se mnou
            </p>
            <p
              className='underline cursor-pointer font-[600] transition-all duration-300 ease-in-out hover:scale-[1.02]'
              onClick={() => handleNavigateToThetaArticle(navigate)}
            >
              Jak funguje theta healing
            </p>
            <p
              className='underline cursor-pointer font-[600] transition-all duration-300 ease-in-out hover:scale-[1.02]'
              onClick={() => handleNavigateToQA(navigate)}
            >
              Otázky a odpovědi
            </p>
          </div>,
          placeholder3
        );
      }
    }
  }, [textData]);

  return (
    <>
      <SEO page={getSEO(id ?? 'articles')} />
      <div className='top-0 pt-24 w-full min-h-screen bg-transparent flex flex-col items-center justify-center'>
        <section className='flex flex-col lg:p-16 lg:pl-[30%] lg:pr-[30%] bg-transparent items-center justify-center w-full'>
          <div className='text-center pt-6 bg-white w-full'>
            <ArticleTitle text={article.title} />
          </div>
          {id === 'proc-theta' && <WhyThetaContent />}
          {id === 'qa' && <QAContent />}
          {id !== 'qa' && id !== 'proc-theta' && (
            <div className='bg-white p-4 lg:pl-20 lg:pr-20 lg:pb-16'>
              {' '}
              <div
                ref={contentRef}
                onLoadedData={() => console.log('loaded')}
                dangerouslySetInnerHTML={{ __html: textData }} // Insert HTML into the component
              />
            </div>
          )}
          <AuthorSign fullsize />
          {/* <Subscribe /> */}
        </section>
        <NextArticles />
        {/* <AuthorSign /> */}
        <Subscribe />
      </div>
    </>
  );
};

export default Article;

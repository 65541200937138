import React, { useEffect, useRef, useState } from 'react';
import bgImage from '../util/img/DSC_6049.webp';
import img1 from '../util/img/sm_DSC_5995.webp';
import img2 from '../util/img/sm_DSC_6013.webp';
import { useNavigate } from 'react-router-dom';
import StyledButton from '../components/StyledButton';
import NewFooter from '../components/NewFooter';
import ArticleTitle from '../components/ArticleTitle';
import Subscribe from '../components/Subscribe';
import FeedbackComponentHome from '../components/FeedbackComponentHome';
import { useAppContext } from '../context/AppContext';
import {
  handleNavigateToAbout,
  handleNavigateToFeedback,
  handleNavigateToReservation,
  handleNavigateToWhyThetaArticle,
} from '../helpers/navigations';

const csq = (
  <span className='text-amber-900 text-lg font-[600] pt-3 lg:pt-0 leading-[1.2] lg:leading-[2.5] pr-3'>
    ■
  </span>
);

const HomePage: React.FC = () => {
  const [visibleDiv, setVisibleDiv] = useState(false);
  const divRef = useRef<HTMLImageElement>(null);
  const [visible, setVisible] = useState(false);
  const { state, setActiveMeeting, setActiveDiscount } = useAppContext();

  useEffect(() => {
    if (window.location.href.includes('#blackfriday')) {
      scrollToSection('bfsection', true);
    }
    // Delay the start of the opacity transition by 2 seconds
    const timeout = setTimeout(() => {
      setVisible(true);
    }, 1000); // 2000 milliseconds = 2 seconds
    const timeout2 = setTimeout(() => {
      scrollToSection('scsection');
    }, 3000);
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            // Set visibility based on the image that is intersecting
            if (entry.target === divRef.current) {
              setVisibleDiv(true);
            }
          }
        });
      },
      { threshold: 0.5 }
    );

    // Observe each image
    if (divRef.current) observer.observe(divRef.current);

    return () => {
      clearTimeout(timeout);
      clearTimeout(timeout2);
      // Unobserve each image
      if (divRef.current) observer.unobserve(divRef.current);
    };
  }, []);

  const navigate = useNavigate();

  const animatedScroll = (targetPosition: number, duration: number) => {
    const startPosition = window.scrollY;
    console.log(startPosition);
    const distance = targetPosition - startPosition - 60;
    let startTime: number | null = null;

    const animation = (currentTime: number) => {
      if (startTime === null) startTime = currentTime;
      const timeElapsed = currentTime - startTime;

      const ease = (t: number, b: number, c: number, d: number) => {
        t /= d / 2;
        if (t < 1) return (c / 2) * t * t + b;
        t--;
        return (-c / 2) * (t * (t - 2) - 1) + b;
      };

      const run = ease(timeElapsed, startPosition, distance, duration);
      window.scrollTo(0, run);

      if (timeElapsed < duration) requestAnimationFrame(animation);
    };

    requestAnimationFrame(animation);
  };

  const scrollToSection = (sectionId: string, testZero: boolean = true) => {
    console.log('---', window.scrollY);
    if (window.scrollY !== 0 && testZero) return;
    const section = document.getElementById(sectionId);
    console.log('scrolling', section);
    if (section) {
      const targetPosition =
        section.getBoundingClientRect().top + window.scrollY;
      animatedScroll(targetPosition, 1000); // 1000 ms = 1 second animation
    }
  };

  return (
    <div className='top-0 lg:pt-24 w-full min-h-screen bg-transparent flex flex-col justify-end'>
      <section
        className='flex lg:hidden flex-col pt-28 bg-transparent -mb-24 items-center justify-between'
        style={{
          backgroundImage: `url(${bgImage})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPositionX: '70%',
        }}
      >
        <div
          className={`flex flex-col w-full h-[1/3]  justify-center items-center text-center bg-transparent pb-20`}
        >
          <div className='absolute top-20 left-5'>
            {' '}
            <h1
              className={`text-transparent uppercase text-lg font-[500] text-center transition-opacity duration-[1000ms] ${
                visible ? 'opacity-100' : 'opacity-0'
              }`}
              style={{ transitionDelay: '0s' }}
            >
              Theta Healing
            </h1>
          </div>

          <h1
            className={` text-slate-100 text-center text-5xl pb-20 font-[500] font-['GreatVibes-Regular'] transition-opacity duration-[1000ms] pb-30  pt-20 ${
              visible ? 'opacity-100' : 'opacity-0'
            }`}
            style={{ transitionDelay: '1s' }}
            id='scsection'
          >
            Cesta Světla
          </h1>
        </div>
      </section>

      <section className='hidden lg:flex flex-col bg-transparent h-screen -mb-24 items-end md:items-center justify-between'>
        <div
          className={`flex flex-col h-full justify-center items-center text-center bg-transparent`}
        >
          <div className='absolute top-[42%] left-[7rem]'>
            {' '}
            <h1
              className={`text-transparent pt-8 uppercase text-3xl font-[500] text-center transition-opacity duration-[1000ms] ${
                visible ? 'opacity-100' : 'opacity-0'
              }`}
              style={{ transitionDelay: '0s' }}
            >
              Theta Healing
            </h1>
          </div>

          <h1
            className={` text-slate-100 text-[6rem] md:text-[7rem] lg:text-[8rem] font-[500] font-['GreatVibes-Regular'] transition-opacity duration-[1000ms] pb-10 lg:pb-30 pt-20 ${
              visible ? 'opacity-100' : 'opacity-0'
            }`}
            style={{ transitionDelay: '100ms' }}
          >
            Cesta Světla
          </h1>
        </div>
        <div className='relative bottom-0 w-full '>
          <NewFooter homepage />
        </div>
        <section className='flex flex-col mb-24 w-full bg-white items-end md:items-center justify-between '>
          <div className='flex mt-12 mb-6 flex-col items-end md:items-center justify-between p-10 lg:p-0'>
            <h2 className="text-2xl font-[600] font-['PetitFormalScript-Regular'] pb-6 text-center lg:text-left">
              "Prožij naplno pocit, že si zasloužíš všechnu lásku a soucit, co
              na světě existuje."
            </h2>
            <p className='text-lg font-[500] leading-[2] lg:leading-[3]'>
              Vnímáš, že se změny nedějí tak, jak si přeješ? Chceš zažít velké
              změny opravdu rychle?
            </p>

            <p className='text-lg font-[500] leading-[2] lg:leading-[3]'>
              Už jsi zkusila všechno možné, a pořád ti nic nepomáhá? Nebo se
              chceš dál rozvíjet a hledáš cesty?
            </p>

            <p className='text-lg font-[500] leading-[3] pt-2'>
              Jsem tu pro tebe.
            </p>
          </div>
        </section>
      </section>

      <section className='flex lg:hidden flex-col bg-white items-end md:items-center justify-between '>
        <div className='flex mt-24 mb-16 flex-col items-end md:items-center justify-between p-10 pt-0 lg:p-0'>
          <h2 className="text-2xl font-[600] font-['PetitFormalScript-Regular'] pb-6 text-center lg:text-left">
            "Prožij naplno pocit, že si zasloužíš všechnu lásku a soucit, co na
            světě existuje."
          </h2>
          <p className='text-lg font-[500] leading-[2] lg:leading-[3]'>
            Vnímáš, že se změny nedějí tak, jak si přeješ? Chceš zažít velké
            změny opravdu rychle?
          </p>

          <p className='text-lg font-[500] leading-[2] lg:leading-[3]'>
            Už jsi zkusila všechno možné, a pořád ti nic nepomáhá? Nebo se chceš
            dál rozvíjet a hledáš cesty?
          </p>

          <p className='text-lg font-[500] leading-[3] pt-2'>
            Jsem tu pro tebe.
          </p>
        </div>
      </section>

      <section className='flex flex-col mt-10 bg-custom-test/90 items-end lg:items-center justify-between lg:ml-[20%] lg:mr-[20%]'>
        <div className='flex flex-col lg:flex-row items-center pt-8 lg:pt-0'>
          <img
            loading='lazy'
            src={img1}
            alt='Logo'
            className='h-auto max-w-80  mb-8 xl:mb-0'
          />
          <div className='flex flex-col p-8 lg:p-10 text-black items-start'>
            <div className='text-center lg:text-start'>
              <ArticleTitle text='Jak ti můžu pomoct?' />
            </div>
            <div className='flex flex-row'>
              {csq}
              <p className='text-lg font-[500] pt-3 lg:pt-0 leading-[1.2] lg:leading-[2.5] '>
                Přivedu tě k energii bezpodmínečné lásky
              </p>
            </div>
            <div className='flex flex-row'>
              {csq}
              <p className='text-lg font-[500] pt-3 lg:pt-0 leading-[1.2] lg:leading-[2.5] '>
                Ukotvím tě ve změně, kterou si přeješ
              </p>
            </div>
            <div className='flex flex-row'>
              {csq}
              <p className='text-lg font-[500] pt-3 lg:pt-0 leading-[1.2] lg:leading-[2.5] '>
                Pomohu ti ujasnit, kdo jsi a co skutečně chceš
              </p>
            </div>
            <div className='flex flex-row'>
              {csq}
              <p className='text-lg font-[500] pt-3 lg:pt-0 leading-[1.2] lg:leading-[2.5] '>
                Pomůžu ti prožít tu pravou úlevu
              </p>
            </div>
            <div className='flex flex-row'>
              {csq}
              <p className='text-lg font-[500] pt-3 lg:pt-0 leading-[1.2] lg:leading-[2.5] '>
                Změním vzorce, které tě omezují
              </p>
            </div>
            <div className='flex flex-row'>
              {csq}
              <p className='text-lg font-[500] pt-3 lg:pt-0 leading-[1.2] lg:leading-[2.5] '>
                Naučím tě každé trápení vidět s lehkostí
              </p>
            </div>
            <div className='flex flex-row '>
              {csq}
              <p className='text-lg font-[500] pt-3 lg:pt-0 leading-[1.2] lg:leading-[2.5] '>
                Navedu tě k většímu uvědomění a sebehodnotě
              </p>
            </div>
            <div className='flex flex-row'>
              {csq}
              <p className='text-lg font-[500] pt-3 lg:pt-0 leading-[1.2] lg:leading-[2.5] '>
                Provedu tě celým procesem v laskavosti a soucitu
              </p>
            </div>
            <div className='flex flex-row pt-4'>
              <StyledButton
                handleClick={() => scrollToSection('buySection', false)}
                text='To chci zažít'
              />
            </div>
          </div>
        </div>
      </section>

      <section className='flex flex-col bg-white items-end md:items-center justify-between w-full mt-10 pt-16 pb-16'>
        <div className='relative z-0 px-6 p-10 lg:ml-[10%] lg:mr-[10%] bg-custom-test justify-center items-center text-center'>
          <ArticleTitle text='Co o mně říkají' />
          <div className='flex flex-col lg:flex-row bg-transparent text-left'>
            {/* Feedback Section */}
            {state.feedbacks
              ?.filter((t: { highlight: boolean }) => t.highlight === true)
              .map(
                (
                  feedback: {
                    minText: string;
                    image: string;
                    name: string;
                    text: string;
                    job: string;
                    highlight: boolean;
                  },
                  index: React.Key | null | undefined
                ) => (
                  <FeedbackComponentHome
                    key={index}
                    image={'/content-data/feedback/img/' + feedback.image}
                    name={feedback.name}
                    job={feedback.job}
                    text={
                      '/content-data/feedback/txt/highlights/' +
                      feedback.minText
                    }
                  />
                )
              )}
          </div>
          <div className='flex w-full items-center justify-center pt-4 lg:pt-16'>
            <p>
              <StyledButton
                handleClick={() => handleNavigateToFeedback(navigate)}
                text='Chci si přečíst víc referencí'
              />
            </p>
          </div>
        </div>
      </section>

      <section className='flex flex-col md:flex-row bg-white mt-10 mb-10 items-center justify-center space-y-8 md:space-y-0 md:space-x-8 pt-16 pb-16'>
        <div
          onClick={() => handleNavigateToAbout(navigate)}
          className='flex flex-col size-[90%] md:size-[24rem] p-4 md:p-8 bg-custom-test items-center justify-start text-center transition-all duration-300 ease-in-out hover:shadow-xl hover:scale-105'
        >
          <div className='flex-col justify-between h-full '>
            <ArticleTitle text='O MNĚ' line />
            <p className='block text-base md:text-lg font-[500] md:leading-[1.5] text-center'>
              Pomáhám překonávat pocit, že se nic nezmění, aby každý mohl najít
              v duši, těle a mysli klid. Naučila jsem se nevzdávat se a získala
              tak vnitřní svobodu.
            </p>
            <p className='block text-base md:text-lg font-[600] md:leading-[2] text-center'>
              Ale vždycky to tak nebylo.
            </p>
          </div>
          <div className='relative bottom-0 justify-between space-x-6 pt-4 pb-4'>
            <StyledButton
              handleClick={() => handleNavigateToAbout(navigate)}
              text='Víc o mně'
            />
          </div>
        </div>

        <div
          onClick={() => {
            handleNavigateToWhyThetaArticle(navigate);
          }}
          className='flex flex-col size-[90%] md:size-[24rem] bg-custom-test p-4 md:p-8 items-center justify-start  text-center transition-all duration-300 ease-in-out hover:shadow-xl hover:scale-105'
        >
          <div className='flex-col justify-between h-full w-full '>
            <ArticleTitle text='Proč Theta healing?' line={true} />
            {/* {csq} */}
            <div className='flex-col justify-center items-center'>
              <p className='block text-base md:text-lg font-[500] md:leading-[1.5] text-center'>
                Bez nutnosti dlouze popisovat situaci promění v lehkosti
                podvědomá přesvědčení a vzorce chování
              </p>

              <p className='block text-base md:text-lg font-[600] md:leading-[2] text-center'>
                Probíhá v empatii a laskavosti
              </p>
            </div>
          </div>
          <div className='relative bottom-0 justify-between space-x-6 pt-4 pb-4'>
            <StyledButton
              handleClick={() => {
                handleNavigateToWhyThetaArticle(navigate);
              }}
              text='Chci vědět víc'
            />
          </div>
        </div>
      </section>

      <section
        id='buySection'
        className='flex flex-col pt-16 pb-10 bg-custom-test items-center '
      >
        <div className='flex flex-col lg:flex-row  bg-white lg:p-6 text-center lg:text-left items-center'>
          <div className='flex flex-col items-center lg:items-start space-y-4'>
            <div className='flex flex-col items-center text-center'>
              <ArticleTitle text='Výměna energie' />
              <p className='text-lg font-[500] leading-[2]'>
                Chceš se osvobodit od všeho, co tě drží zpátky?
              </p>
              <div className='grid grid-cols-1 lg:grid-cols-2 gap-4 p-4'>
                <div
                  onClick={() => {
                    setActiveMeeting('konzultace');
                    handleNavigateToReservation(navigate);
                  }}
                  className='flex flex-col bg-custom-test p-8 size-[19rem] items-center transition-all duration-300 ease-in-out hover:shadow-xl hover:scale-105'
                >
                  <div className='relative h-1rem w-[19rem] mb-6'>
                    <p className='text-lg font-[500]  text-center -mt-3 text-white bg-amber-900'>
                      Vyzkoušej
                    </p>
                  </div>
                  <div className='flex-col justify-between h-full  text-center'>
                    <ArticleTitle text='20 minut' line />
                    <p className='text-md font-[500]  text-center'>
                      Konzultace a meditace
                    </p>
                    <p className='text-lg font-[500]  text-center'>
                      Theta Healing
                    </p>

                    <p className='text-lg font-[600]  text-center'>ZDARMA</p>
                  </div>
                  <div className='relative bottom-0 justify-between   '>
                    <StyledButton
                      handleClick={() => {
                        setActiveMeeting('konzultace');
                        handleNavigateToReservation(navigate);
                      }}
                      text='Rezervace'
                    />
                  </div>
                </div>
                <div
                  onClick={() => {
                    setActiveMeeting('sezeni60min');
                    handleNavigateToReservation(navigate);
                  }}
                  className='flex flex-col bg-custom-test p-8 size-[19rem] items-center transition-all duration-300 ease-in-out hover:shadow-xl hover:scale-105'
                >
                  <div className='relative h-1rem w-[19rem] mb-6'>
                    <p className='text-lg font-[500]  text-center -mt-3 bg-[#f4c9d5cc]'>
                      Nejprodávanější
                    </p>
                  </div>
                  <div className='flex-col justify-between h-full  text-center'>
                    <ArticleTitle text='60 minut' line />

                    <p className='text-lg font-[500]  text-center'>
                      Theta Healing
                    </p>

                    <p className='text-lg font-[600]  text-center'>2000 Kč</p>
                  </div>
                  <div className='relative bottom-0 justify-between '>
                    <StyledButton
                      handleClick={() => {
                        setActiveMeeting('sezeni60min');
                        handleNavigateToReservation(navigate);
                      }}
                      text='Rezervace'
                    />
                  </div>
                </div>
                <div
                  onClick={() => {
                    setActiveMeeting('sezeni90min');
                    handleNavigateToReservation(navigate);
                  }}
                  className='flex flex-col bg-custom-test p-8 size-[19rem] items-center transition-all duration-300 ease-in-out hover:shadow-xl hover:scale-105'
                >
                  <div className='relative h-1rem w-[19rem] mb-6'>
                    <p className='text-lg font-[500]  text-center -mt-3 text-transparent'>
                      Nejprodávanější
                    </p>
                  </div>
                  <div className='flex-col justify-between h-full  text-center'>
                    <ArticleTitle text='90 minut' line />

                    <p className='text-lg font-[500]  text-center'>
                      Theta Healing
                    </p>

                    <p className='text-lg font-[600]  text-center'>2500 Kč</p>
                  </div>
                  <div className='relative bottom-0 justify-between   '>
                    <StyledButton
                      handleClick={() => {
                        setActiveMeeting('sezeni90min');
                        handleNavigateToReservation(navigate);
                      }}
                      text='Rezervace'
                    />
                  </div>
                </div>
                <div
                  onClick={() => {
                    setActiveMeeting('sezeni5x60min');
                    handleNavigateToReservation(navigate);
                  }}
                  className='flex flex-col bg-custom-test p-8 size-[19rem] items-center transition-all duration-300 ease-in-out hover:shadow-xl hover:scale-105'
                >
                  <div className='relative h-1rem w-[19rem] mb-6'>
                    <p className='text-lg font-[500]  text-center -mt-3 text-transparent'>
                      Nejprodávanější
                    </p>
                  </div>
                  <div className='flex-col justify-between h-full  text-center'>
                    <ArticleTitle text='5 x 60 MINUT' uc={false} line />

                    <p className='text-lg font-[500]  text-center'>
                      Theta Healing
                    </p>
                    <p className='line-through text-base font-[500]  text-center'>
                      10 000 Kč
                    </p>
                    <p className='text-lg font-[600]  text-center'>9000 Kč</p>
                  </div>
                  <div className='relative bottom-0 justify-between '>
                    <StyledButton
                      handleClick={() => {
                        setActiveMeeting('sezeni5x60min');
                        handleNavigateToReservation(navigate);
                      }}
                      text='Rezervace'
                    />
                  </div>
                </div>
              </div>
              {/* <div
                ref={divRef}
                className={`relative bottom-0 p-3 w-full text-black bg-custom-test text-center transition-opacity duration-2000 opacity-0 ${
                  visibleDiv ? 'opacity-100' : ''
                }`}
              >
                <ArticleTitle
                  text={'Sleva 40% s kódem: "BLACKFRIDAY"'}
                  subtitle='Platí do 15.12.2024'
                />
              </div> */}
              <div
                id='bfsection'
                className='h-96 w-full mt-3 overflow-hidden p-8 lg:p-0 pb-8 lg:pb-0 items-center'
              >
                <img
                  loading='lazy'
                  src={img2}
                  alt='Logo'
                  className='h-full w-full object-cover'
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <Subscribe />
    </div>
  );
};
export default HomePage;

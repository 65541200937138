import React from 'react';
import ArticleTitle from './../ArticleTitle';
import StyledButton from '../StyledButton';
import { useNavigate } from 'react-router-dom';
import {
  handleNavigateToPrubeh,
  handleNavigateToQA,
  handleNavigateToReservation,
  handleNavigateToThetaArticle,
} from '../../helpers/navigations';
import { useAppContext } from '../../context/AppContext';

const WhyThetaContent: React.FC = ({}) => {
  const navigate = useNavigate();
  const { setActiveMeeting } = useAppContext();

  const csq = (
    <span className='text-amber-900 text-lg font-[600] pt-[1px] lg:pt-0 leading-[1.2]  pr-2'>
      ■
    </span>
  );

  const emphStyle = 'text-[1.2rem] font-[600] text-amber-900 pt-2 pb-3';

  return (
    <section className='flex flex-col w-full bg-white p-4 lg:p-10 lg:pt-4'>
      <div className='flex flex-col items-center pb-6'>
        <section className='flex flex-col w-full items-start'>
          <p className=''>
            Přeměna podvědomých přesvědčení a vzorců v lehkosti{' '}
          </p>
          <p className='pb-2'>Víra v něco víc, co nás přesahuje</p>
          <div className='flex flex-col items-start'>
            <p className={`${emphStyle}`}>Co očekávat od sezení:</p>
            <div className='flex flex-row items-start justify-start'>
              {csq}
              <p className=''>Napojující meditaci </p>
            </div>
            <div className='flex flex-row'>
              {csq}
              <p className=''>
                Řešení tvojí situace bez nutnosti o tom dlouze mluvit{' '}
              </p>
            </div>
            <div className='flex flex-row'>
              {csq}
              <p className=''>Uvolnění bloků </p>
            </div>
            <div className='flex flex-row'>
              {csq}
              <p className=''>Úlevu a radost ze změny </p>
            </div>
            <div className='flex flex-row'>
              {csq}
              <p className=''>Hlubší propojení se sebou samotnou </p>
            </div>
            <p className={`${emphStyle}`}>Co potřebuji před sezením?</p>
            <p className=''>Mít téma, které chceš řešit.</p>
            <p className={`${emphStyle}`}>Jaké problémy můžu metodou řešit?</p>
            <p className=''>Cokoli, co aktuálně prožíváš nebo jsi prožila. </p>
            <p className={`${emphStyle}`}>Jak poznám, že je to pro mě?</p>
            <div className='flex flex-row'>
              {csq}
              <p className=''>
                Kladu důraz na respekt a na možnost cokoli odmítnout{' '}
              </p>
            </div>
            <div className='flex flex-row'>
              {csq}
              <p className=''>Je pro mě důležitá laskavost a empatie</p>
            </div>
            <div className='flex flex-row'>
              {csq}
              <p className=''>Chci se ve svém životě posunout</p>
            </div>
          </div>
        </section>
        <section className='flex flex-col items-start w-full  space-y-1 pt-5'>
          <StyledButton
            handleClick={() => {
              setActiveMeeting('konzultace');
              handleNavigateToReservation(navigate);
            }}
            text='Chci 20 min zdarma'
          />
          <div className='text-center w-full pt-4'>
            <ArticleTitle text='Kam dál?' />
          </div>
          <StyledButton
            handleClick={() => {
              handleNavigateToPrubeh(navigate);
            }}
            secondary
            text='Průběh sezení se mnou'
          />
          <StyledButton
            handleClick={() => {
              handleNavigateToThetaArticle(navigate);
            }}
            secondary
            text='Jak funguje theta healing'
          />
          <StyledButton
            handleClick={() => {
              handleNavigateToQA(navigate);
            }}
            secondary
            text='Otázky a odpovědi'
          />
        </section>
      </div>
    </section>
  );
};

export default WhyThetaContent;

import React from 'react';

import NavigationLink from './navigation/NavigationLink';

const NewNavigationBar: React.FC = () => {
  return (
    <nav className='hidden bg-transparent pt-4 rounded-lg lg:flex justify-center'>
      <ul className='flex text-xs lg:text-3xl space-x-2 text-white'>
        <NavigationLink hasIcon navTo={'/'} navString={'     '} />
        <NavigationLink navTo={'/o-mne'} navString={'O MNĚ'} />
        <NavigationLink navTo={'/clanky'} navString={'ČLÁNKY'} />
        <NavigationLink navTo={'/certifikace'} navString={'CERTIFIKACE'} />
        <NavigationLink navTo={'/rezervace'} navString={'REZERVACE'} />
        <NavigationLink navTo={'/zpetna-vazba'} navString={'RECENZE'} />
        <NavigationLink navTo={'/kontakt'} navString={'KONTAKT'} />
      </ul>
    </nav>
  );
};

export default NewNavigationBar;

import React from 'react';
import ArticleTitle from './../ArticleTitle';
import StyledButton from '../StyledButton';
import { useNavigate } from 'react-router-dom';
import {
  handleNavigateContact,
  handleNavigateToPrubeh,
  handleNavigateToThetaArticle,
  handleNavigateToWhyThetaArticle,
} from '../../helpers/navigations';

const QAContent: React.FC = ({}) => {
  const navigate = useNavigate();

  const csq = (
    <span className='text-[#78350f] text-lg font-[600] pt-[10px] lg:pt-0 leading-[1.2] lg:leading-[2.5] pr-2'>
      ■
    </span>
  );

  const emphStyle = 'text-[1.2rem] font-[600] text-amber-900 pt-2 pb-3';

  return (
    <section className='flex flex-col w-full bg-white p-4 lg:p-10'>
      <div className='flex flex-col items-center pb-6'>
        <div className='flex flex-col'>
          <div className='flex flex-row pt-1'>
            {csq}
            <p className={`${emphStyle}`}>Probíhá sezení online?</p>
          </div>
          <p>Ano, vše probíhá z pohodlí tvého domova.</p>

          <div className='flex flex-row pt-1'>
            {csq}
            <p className={`${emphStyle}`}>Jak dlouho trvá sezení?</p>
          </div>
          <p>60 nebo 90 minut, záleží na tobě. </p>

          <div className='flex flex-row pt-1'>
            {csq}
            <p className={`${emphStyle}`}>Jaké problémy mohu metodou řešit?</p>
          </div>
          <p>
            Psychické (Například vztahy, finance, kariéru) i fyzické (bolesti,
            onemocnění).
          </p>

          <div className='flex flex-row pt-1'>
            {csq}
            <p className={`${emphStyle}`}>Jak rychle uvidím výsledky?</p>
          </div>
          <p>
            Je možné, že už po prvním sezení, nicméně je důležité mít trpělivost
            a otevřenost vůči procesu.
          </p>

          <div className='flex flex-row pt-1'>
            {csq}
            <p className={`${emphStyle}`}>Je Theta healing nepříjemný?</p>
          </div>
          <p>
            Právě naopak většina klientů zažívá pocity uvolnění a osvobození už
            během sezení.
          </p>

          <div className='flex flex-row pt-1'>
            {csq}
            <p className={`${emphStyle}`}>
              Potřebuju nějaké předchozí zkušenosti?
            </p>
          </div>
          <p>
            Není potřeba mít žádné předchozí zkušenosti. Metoda je pro každého,
            kdo chce zlepšit svůj život.
          </p>

          <div className='flex flex-row pt-1'>
            {csq}
            <p className={`${emphStyle}`}>Jak se připravit na sezení?</p>
          </div>
          <p>
            Není potřeba žádná příprava, stačí mít představu co chceš řešit za
            téma.
          </p>

          <div className='flex flex-row pt-1'>
            {csq}
            <p className={`${emphStyle}`}>Musím mít víru v Boha?</p>
          </div>
          <p>
            Ne, stačí otevřenost vůči myšlence v něco víc, co nás přesahuje.
          </p>

          <div className='flex flex-row pt-1'>
            {csq}
            <p className={`${emphStyle}`}>
              Můžu na sezení, <span className='whitespace-nowrap'> i když</span>
              <span className='whitespace-nowrap'> beru léky?</span>
            </p>
          </div>
          <p>
            Ano, není tam omezení. Nenahrazuje však lékařskou péči, pouze
            doplňuje.
          </p>

          <div className='flex flex-row pt-1'>
            {csq}
            <p className={`${emphStyle}`}>
              Můžu na sezení{' '}
              <span className='whitespace-nowrap'>v těhotenství?</span>
            </p>
          </div>
          <p>Ano, není tam omezení.</p>
        </div>
        <section className='flex flex-col lg:flex-row w-full lg:space-x-6 space-y-3 lg:space-y-0 pt-6'>
          <StyledButton
            handleClick={() => handleNavigateToWhyThetaArticle(navigate)}
            text='Proč Theta healing?'
          />
          <StyledButton
            handleClick={() => handleNavigateToPrubeh(navigate)}
            text='Jak probíhá sezení?'
          />
        </section>
        <section className='flex flex-col items-center text-center space-y-3 pt-12'>
          {/* <p className='flex pt-12 justify-center lg:justify-start space-x-0 lg:space-x-4 pb-5'>
            <StyledButton
              handleClick={handleClickReservation}
              text='Rezervace schůzky'
            />
          </p> */}
          <ArticleTitle text='Máš další otázky?' />

          <p className='flex justify-center lg:justify-start space-x-0 lg:space-x-4'>
            <StyledButton
              handleClick={() => handleNavigateContact(navigate)}
              text='Kontaktní formulář'
            />
          </p>
        </section>
      </div>
    </section>
  );
};

export default QAContent;

import React, { useEffect, useState } from 'react';
import ArticleLink from './ArticleLink';
import { useParams } from 'react-router-dom';

const NextArticles: React.FC = ({}) => {
  const [articles, setArticles] = useState<any>({});
  const { id } = useParams<{ id: string }>();

  useEffect(() => {
    fetch('/content-data/articles/articles.json')
      .then((response) => response.json())
      .then((data) => {
        setArticles(data);
      })
      .catch((error) => console.error('Error loading feedback data:', error));
  }, []);
  return (
    <section className={'p-8 pb-20 lg:pl-[20%] lg:pr-[20%] bg-custom-test'}>
      <div className='grid grid-cols-1 lg:grid-cols-3 gap-6'>
        {articles?.articles
          ?.filter((a: { id: string | undefined }) => a.id !== id)
          .map(
            (article: {
              id: string;
              title: string;
              summary: string;
              image: string;
              hide: boolean;
            }) =>
              !article.hide && (
                <div
                  key={article.id}
                  className='p-4 border rounded shadow hover:shadow-lg transition-shadow duration-200 bg-white'
                >
                  <ArticleLink
                    articleId={article.id}
                    title={article.title}
                    summary={article.summary}
                  >
                    {/* Image at the top */}
                    <img
                      loading='lazy'
                      src={'/content-data/articles/img/' + article.image}
                      alt={article.title}
                      className='w-full h-0 lg:h-48 object-cover mb-4' // Margin-bottom to separate from the header
                    />
                    {/* Summary at the bottom */}
                  </ArticleLink>
                </div>
              )
          )}
      </div>
    </section>
  );
};

export default NextArticles;

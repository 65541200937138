import React from 'react';
import ArticleTitle from './ArticleTitle';
import profile from '../util/img/profile.webp';
import { handleNavigateToAbout } from '../helpers/navigations';
import { useNavigate } from 'react-router-dom';

interface AuthorSignProps {
  fullsize?: boolean;
}

const AuthorSign: React.FC<AuthorSignProps> = ({ fullsize = false }) => {
  const navigate = useNavigate();
  return (
    <section className='p-8 w-full bg-white'>
      {fullsize && (
        <div className='h-px w-full bg-secondary-button-color mb-5' />
      )}
      <div
        className={`flex flex-row items-center ${
          fullsize ? 'lg:pl-4 lg:pr-4' : ' lg:pl-[20%] lg:pr-[20%]'
        } `}
      >
        <img
          loading='lazy'
          src={profile}
          alt='Profile'
          className='w-24 h-24 rounded-full shadow-lg shadow-amber-900'
        />
        <div className='flex flex-col pl-8'>
          <ArticleTitle
            text={'Veronika Hývlová'}
            subtitle='Průvodkyně metody THETA HEALING'
          />
          <p className='hidden lg:block font-[500] '>
            "Pomáhám překonávat pocit, že se nikdy nic nezmění, aby každý mohl
            najít v duši, mysli a těle klid."{' '}
            <span
              className='font-[600] underline cursor-pointer text-amber-900'
              onClick={() => {
                handleNavigateToAbout(navigate);
              }}
            >
              {'Více informací o mně zjistíš tady >>'}
            </span>
          </p>
        </div>
      </div>
      <p className='lg:hidden font-[500] '>
        "Pomáhám překonávat pocit, že se nikdy nic nezmění, aby každý mohl najít
        v duši, mysli a těle klid."{' '}
        <span
          className='font-[600] underline cursor-pointer text-amber-900'
          onClick={() => {
            handleNavigateToAbout(navigate);
          }}
        >
          {'Více informací o mně zjistíš tady >>'}
        </span>
      </p>
    </section>
  );
};

export default AuthorSign;

import React from 'react';
import { Link } from 'react-router-dom';
import ArticleTitle from './ArticleTitle';

interface ArticleLinkProps {
  articleId: string;
  title: string;
  summary: string;
  children: React.ReactNode;
}

const ArticleLink: React.FC<ArticleLinkProps> = ({
  articleId,
  title,
  summary,
  children,
}) => {
  const limit = 30;
  const truncateText = (input: string): string => {
    const words = input.split(' ');
    if (words.length > limit) {
      return words.slice(0, limit).join(' ') + '...';
    }
    return input;
  };

  return (
    <Link to={`/clanky/${articleId}`} className='group '>
      <div className='cursor-pointer flex flex-col items-start bg-white'>
        {children} {/* This is where the image and summary will go */}
        <ArticleTitle text={title} />
        <p className='text-gray-700'>{truncateText(summary)}</p>
      </div>
    </Link>
  );
};

export default ArticleLink;
